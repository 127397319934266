import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import AccessoryImage from "~/accessory-image";

export default ({ project, ...props }) => {
    const image = project.mainImage.asset;

    return (
        <Link
            to={`/referenzen/${project.slug.current}/`}
            className="no-underline group hover:no-underline"
            {...props}
        >
            <AccessoryImage
                background={image.metadata.palette.color.background}
                foreground={image.metadata.palette.color.foreground}
            >
                <GatsbyImage image={getImage(image)} alt={project.mainImage.alt || ""} />
            </AccessoryImage>
            <div className="pt-sm md:pt-md">
                <h3 className="m-0 font-bold text-inherit">{project.title}</h3>
                {project.location}
            </div>
        </Link>
    );
};
