import React from "react";
import Column from "~/column";
import Container from "~/container";

const Intro = ({ title, className, image, spacing, children, ...props }) => {
    return (
        <Container spacing={true} className={`${className || ""}`} {...props}>
            {title && <h1 className="sr-only">{title}</h1>}
            <Column className="relative z-10 text-3xl font-bold leading-snug md:text-4xl md:leading-snug lg:text-5xl lg:leading-snug">
                {children}
            </Column>

            {image && (
                <div className="relative z-0 -mt-3xl md:-mt-4xl lg:-mt-5xl xl:-mt-6xl xl:w-5/6 xl:mx-auto dark:mt-2xl md:dark:mt-3xl lg:dark:mt-4xl xl:dark:mt-6xl">
                    {image}
                </div>
            )}
        </Container>
    );
};

Intro.defaultProps = {
    spacing: true,
};

export default Intro;
