import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Column from "~/column";
import Container from "~/container";
import Intro from "~/intro";
import Layout from "~/layout";
import ProjectItem from "~/project-item";
import SEO from "~/seo";

const ProjectsPage = (props) => {
    return (
        <StaticQuery
            query={query}
            render={(data) => {
                return (
                    <Layout>
                        <Intro title="Referenzen">
                            Ein Blick in einige unserer Referenzen. Moderne Arbeitswelten, die
                            herausragendes Design, zeitlose Ästhetik und ergonomische Perfektion
                            verbinden.
                        </Intro>
                        <Container
                            spacing={true}
                            className="space-y-gutter sm:flex sm:flex-wrap sm:space-y-0"
                        >
                            {data.projects.edges.map((edge) => (
                                <Column
                                    className="text-base sm:w-1/2 sm:py-gutter md:text-lg lg:w-1/3 lg:text-base xl:text-lg"
                                    key={edge.node.id}
                                >
                                    <ProjectItem project={edge.node} />
                                </Column>
                            ))}
                        </Container>
                    </Layout>
                );
            }}
        />
    );
};

export const Head = () => (
    <SEO
        title="Referenzen"
        description="Sehen Sie sich einige unserer Referenzen aus Planung und Objekteinrichtung an."
    />
);

export default ProjectsPage;

const query = graphql`
    query ProjectsPageQuery {
        projects: allSanityProject(sort: { fields: orderRank }, filter: { visible: { eq: true } }) {
            edges {
                node {
                    id
                    title
                    location
                    slug {
                        current
                    }
                    mainImage {
                        alt
                        asset {
                            gatsbyImageData(width: 402, height: 226)
                            metadata {
                                palette {
                                    color: vibrant {
                                        background
                                        foreground
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
